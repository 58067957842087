@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&display=swap');


.swiper {
  width: 100%;
  height: auto;
}

/* .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
} */

.swiper .swiper-slide .mySwiper {
  height: 550px;
  line-height: 300px;
}

@media (max-width: 767px) {
  .swiper .swiper-slide .mySwiper {
    height: 169px;
    line-height: 200px;
  }
}


@layer base {

    /* body {
        height: 100vh;
        overflow: hidden;
        font-size: 14px;
    }

    a:hover {
        color: rgb(38 71 163 / 0.9);

    }

    input,
    textarea,
    select {
        padding: 10px 16px;
        background: white;
        border-radius: 6px;
    }

    input:focus,
    textarea:focus,
    select:focus {
        outline: none;
        border-color: rgb(124, 4, 199, 0.4);

    }

    input::placeholder {
        font-size: 14px !important;
        color: rgba(0, 0, 0, 0.5);
    }

    textarea::placeholder {
        font-size: 14px !important;
        color: #878993;
    } */
}

.nav_element {
    @apply text-sm text-black font-medium hover:text-[#0F8C3B] hover:underline underline-offset-2 decoration-1 cursor-pointer duration-300;
  }
.slide_btn {
    @apply rounded-full w-14 h-12 border-[1px] border-gray-400 flex justify-center items-center hover:cursor-pointer hover:bg-gray-700 hover:text-white active:bg-gray-900 duration-300;
  }

  .green_btn {
    @apply  border border-[#0F8C3B]  bg-[#0F8C3B] mt-2 py-1 px-2 md:py-1 md:px-4 text-white transition-all hover:bg-white hover:text-[#071c1f] hover:border-[#071c1f] text-center text-[1rem] md:text-sm font-rajdhani font-bold flex items-center justify-center;
  }
  .black_btn {
    @apply  border border-[#0f100f]  bg-[#0f100f] mt-2 py-1 px-2 md:py-1 md:px-4 text-white transition-all hover:bg-[#0f100f] hover:text-[#ffffff] hover:border-[#ffffff] text-center text-[1.19rem] md:text-sm font-rajdhani font-bold flex items-center justify-center;
  }
  
  .outline_btn {
    @apply rounded-xl  border-[.1rem] bg-transparent mt-2 py-1 px-2 md:py-1 md:px-4 text-[#071c1f] transition-all hover:bg-[#071c1f] hover:text-white text-center text-[.5rem] md:text-sm font-rajdhani font-bold shadow-md flex items-center justify-center;
  }

  .btn_modal {
    @apply bg-gray-800 hover:bg-gray-700 text-gray-100 font-medium px-7 py-1.5 rounded;
  }

  .step-item {
    @apply relative flex flex-col justify-center items-center w-36;
  }

  .step-item:not(:first-child):before {
    @apply content-[''] bg-slate-200 absolute w-full h-[3px] right-2/4 top-1/3 -translate-y-2/4;
  }

  .step {
    @apply w-10 h-10 flex items-center justify-center z-10 relative bg-slate-700 rounded-full font-semibold text-white;
  }

  .active .step {
    @apply bg-sky-600;
  }

  .complete .step {
    @apply bg-green-600;
  }

  .complete p {
    @apply text-gray-900;
  }

  .complete:not(:first-child):before,.active:not(:first-child):before {
    @apply bg-green-600;
  }

  @layer utilities {
    @keyframes fade-right {
      0% {
        opacity: 0;
        transform: translateX(-10px);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  @layer components {
    .animation-fade-right {
      animation: fade-right 1.5s infinite linear;
    }
  }